<template>
	<div class="search">
		<div class="search-bar" v-if="isUserLoggedIn">
			<div class="search__box">
				<i class="fa fa-search search__icon" @click="handleSearchByTransaction(inputSearchByTransaction)" />
				<input
					v-model="inputSearchByTransaction"
					ref="transactionSearchBox"
					type="search"
					class="search__input"
					:placeholder="$t(`search.byTransactionPlaceholder`)"
					@keydown.enter="handleSearchByTransaction(inputSearchByTransaction)"
				/>
			</div>
			<div v-show="transactionSuggestions && transactionSuggestions.length > 1" class="search__suggestions">
				<div
					v-for="transaction in transactionSuggestions"
					class="search__suggestion"
					@click="handleSearchByTransaction(null, toRaw(transaction))"
				>
					<slot name="suggestionIcon"></slot>
					{{
						transaction.location.address ||
						transaction.location.municipality + " " + (transaction.location.borough || "")
					}}
				</div>
			</div>
		</div>

		<div class="search-bar">
			<div class="search__box">
				<i class="fa fa-search search__icon" @click="handleSearchByParcel(inputSearchByParcel)" />
				<input
					v-model="inputSearchByParcel"
					ref="parcelSearchBox"
					type="search"
					class="search__input"
					:placeholder="$t(`search.byParcelPlaceholder`)"
					@keydown.enter="handleSearchByParcel(inputSearchByParcel)"
				/>
			</div>
		</div>

		<div class="search-bar">
			<div class="search__box">
				<i class="fa fa-search search__icon" @click="event => handleSearchByAddress(event)" />
				<input
					v-model="inputSearchByAddress"
					type="search"
					class="search__input"
					:placeholder="$t(`search.byAddressPlaceholder`)"
					@click="isSuggestionsActive = true"
					@blur="focusOutHandler"
					@keydown.enter="event => handleSearchByAddress(event)"
				/>
			</div>
			<div v-show="addressSuggestions && isSuggestionsActive" class="search__suggestions">
				<div
					v-for="suggestion in addressSuggestions"
					:key="suggestion.id"
					class="search__suggestion"
					@click="event => handleSearchByAddress(event, suggestion)"
				>
					<slot name="suggestionIcon"></slot>
					{{ suggestion.address.label }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import debounce from "lodash.debounce"
import { ref, watch, toRaw } from "vue"
import { useI18n } from "vue-i18n"
import * as addressClient from "@/components/map/addressClient"
import { getParcel, getTransactionsByDocumentNumber } from "@/utils/ApiClient"
import { UseRootStore } from "@/model/RootStore"
import { storeToRefs } from "pinia"

export default {
	props: {
		mapCenter: {
			type: Object,
			required: true,
		},
	},
	emits: ["search-by-address", "search-by-parcel", "search-by-transaction"],
	setup(props, { emit }) {
		const store = UseRootStore()
		const { userApiToken, isUserLoggedIn } = storeToRefs(store)

		const { t } = useI18n()

		const inputSearchByParcel = ref("")
		const inputSearchByTransaction = ref("")
		const parcelSearchBox = ref(null)
		const transactionSearchBox = ref(null)

		const inputSearchByAddress = ref("")
		const addressSuggestions = ref([])
		const transactionSuggestions = ref([])
		const isSuggestionsActive = ref(true)

		const handleSearchByAddress = (event, suggestion = addressSuggestions.value[0]) => {
			if (!suggestion) return

			isSuggestionsActive.value = false
			inputSearchByAddress.value = suggestion.title
			parcelSearchBox.value.blur()

			const { resultType, position, mapView } = suggestion
			emit("search-by-address", {
				lng: position.lng,
				lat: position.lat,
				bounds: mapView && [
					[mapView.west, mapView.south],
					[mapView.east, mapView.north],
				],
				isRegion: addressClient.isRegion(resultType),
			})
		}

		const handleSearchByParcel = debounce(async searchValue => {
			parcelSearchBox.value.blur()

			const parcelId = searchValue.replace(/\s+/g, "")
			if (!parcelId) {
				return
			}

			const parcel = await getParcel(parcelId)
			if (!parcel) {
				alert(t("search.invalidParcelNumber"))
				return
			}

			const { longitude: lng, latitude: lat } = parcel.location.coordinates
			emit("search-by-parcel", { type: "parcelNumber", lng, lat }, parcelId)
		}, 200)

		const handleSearchByTransaction = debounce(async (documentNumber, transaction) => {
			transactionSearchBox.value.blur()

			if (!transaction) {
				const transactions = await getTransactionsByDocumentNumber(userApiToken.value, documentNumber)
				transaction = transactions[0]

				if (!transaction) {
					alert(t("search.invalidTransactionDocumentNumber"))
					return
				}
			}

			emit("search-by-transaction", transaction)

			// reset suggestions
			transactionSuggestions.value = []
		}, 200)

		const suggestionsHandler = debounce(async () => {
			// reset result set
			addressSuggestions.value = []

			// normalize address input
			const inputAddress = inputSearchByAddress.value.trim()
			if (!Boolean(inputAddress)) {
				return
			}

			// fetch suggestions corresponding to input address
			addressSuggestions.value = await addressClient.getSuggestions(
				inputAddress,
				props.mapCenter,
				10 // max results
			)
		}, 300)

		const focusOutHandler = () => {
			setTimeout(() => {
				isSuggestionsActive.value = false
			}, 200)
		}

		watch(inputSearchByAddress, async () => {
			await suggestionsHandler()
		})

		watch(inputSearchByTransaction, async documentNumber => {
			const num = documentNumber.trim()
			const transactions = await getTransactionsByDocumentNumber(userApiToken.value, num)
			transactionSuggestions.value = transactions
		})

		return {
			toRaw,
			isUserLoggedIn,
			handleSearchByAddress,
			handleSearchByParcel,
			handleSearchByTransaction,
			inputSearchByAddress,
			inputSearchByParcel,
			inputSearchByTransaction,
			addressSuggestions,
			transactionSuggestions,
			isSuggestionsActive,
			focusOutHandler,
			parcelSearchBox,
			transactionSearchBox,
		}
	},
}
</script>

<style lang="scss" scoped>
$grey-strong: #888;
$grey-medium: #eee;
$grey-light: #f8f8f8;
$background-light: #fff;
$border-radius: 10px;

.search {
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translate(-50%, -35%);
	width: 35rem;
	font-size: 14px;

	&__box {
		display: flex;
		background-color: $background-light;
		border-radius: $border-radius;
		margin-bottom: 5px;
		box-shadow: 0 4px 18px -6px rgba(0, 0, 0, 0.26);

		&:first-child {
			margin-bottom: 5px;
		}
	}
	&__icon {
		display: inline-box;
		width: 4rem;
		font-size: 12px;
		opacity: 0.5;
		cursor: pointer;
		color: #adadad;

		&::before {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
		&:hover {
			opacity: 1;
		}
	}

	&__input {
		background: none;
		position: relative;
		width: 34rem;
		width: 100%;
		height: 4rem;
		font-size: 1.4rem;
		padding: 0rem 2rem 0rem 0rem;
		border: none;
		border-radius: 1rem;
		outline: none;
	}

	.search-bar {
		position: relative;
	}

	&__suggestions {
		position: absolute;
		top: 110%;
		left: 0;
		width: 100%;
		background-color: $background-light;
		max-height: 320px;
		box-shadow: 0 14px 18px -6px rgba(0, 0, 0, 0.26);
		border-bottom-right-radius: $border-radius;
		border-bottom-left-radius: $border-radius;
		overflow: auto;
		z-index: 1000;
	}

	&__suggestion {
		display: flex;
		align-items: center;
		column-gap: 12px;
		position: relative;
		text-align: left;
		cursor: pointer;
		padding: 12px 16px;
		font-size: 12px;
		color: $grey-strong;
		&:first-of-type::before {
			content: "";
			position: absolute;
			left: 2%;
			right: 2%;
			top: 0;
			background-color: $grey-medium;
			height: 1px;
		}
		&:not(:last-of-type)::after {
			content: "";
			position: absolute;
			left: 2%;
			right: 2%;
			bottom: 0;
			background-color: $grey-medium;
			height: 1px;
		}
		&:hover {
			background-color: $grey-light;
		}
	}
}
</style>
