import { z } from "zod"

export const LayerType = {
	landerz: "landerz",
	off_market: "off_market",
	fbso: "fsbo",
	external: "external",
	co_listing: "co_listing",
	transaction: "transaction",
} as const

export const LayerTypes = Object.values(LayerType)

export const DevelopmentType = {
	residential: "Résidentiel",
	mixed: "Mixte",
	commercial: "Commercial",
	subdivision: "Lotissement",
	unidentified: "Inconnu",
	agricultural: "Agricole",
	industrial: "Industriel",
	institutional: "Institutionnel",
} as const

export enum DevelopmentPotential {
	High = "High",
	Medium = "Medium",
	Low = "Low",
	None = "None",
	Unspecified = "Unspecified",
}

export enum Borough {
	Verdun = "Verdun",
	LaBaie = "La Baie",
	LeVieuxLongueuil = "Le Vieux-Longueuil",
	Desjardins = "Desjardins",
	LaHauteSaintCharles = "La Haute-Saint-Charles",
	Grenville = "Grenville",
	CoteDesNeigesNotreDameDeGrace = "Côte-des-Neiges - Notre-Dame-de-Grâce",
	MontrealNord = "Montréal-Nord",
	AhuntsicCartierville = "Ahuntsic-Cartierville",
	Jonquiere = "Jonquière",
	Lachine = "Lachine",
	LesChutesDeLaChaudiereEst = "Les Chutes-de-la-Chaudière-Est",
	PierrefondsRoxboro = "Pierrefonds - Roxboro",
	Calumet = "Calumet",
	Outremont = "Outremont",
	LesRivieres = "Les Rivières",
	SaintLaurent = "Saint-Laurent",
	MacNider = "MacNider",
	Chicoutimi = "Chicoutimi",
	Arrondissement4 = "Arrondissement 4",
	Arrondissement2 = "Arrondissement 2",
	Beauport = "Beauport",
	LeSudOuest = "Le Sud-Ouest",
	SainteFoySilleryCapRouge = "Sainte-Foy - Sillery - Cap-Rouge",
	SaintHubert = "Saint-Hubert",
	Arrondissement1 = "Arrondissement 1",
	RivieredesPrairiesPointeAuxTrembles = "Rivière-des-Prairies - Pointe-aux-Trembles",
	LIleBizardSainteGenevieve = "LÎle-Bizard - Sainte-Geneviève",
	LePlateauMontRoyal = "Le Plateau-Mont-Royal",
	VilleMarie = "Ville-Marie",
	MercierHochelagaMaisonneuve = "Mercier - Hochelaga-Maisonneuve",
	RosemontLaPetitePatrie = "Rosemont - La Petite-Patrie",
	Arrondissement3 = "Arrondissement 3",
	Charlesbourg = "Charlesbourg",
	GreenfieldPark = "Greenfield Park",
	LaSalle = "LaSalle",
	LaCiteLimoilou = "La Cité-Limoilou",
	VilleraySaintMichelParcExtension = "Villeray - Saint-Michel - Parc-Extension",
	SaintLeonard = "Saint-Léonard",
	LesChutesDeLaChaudiereOuest = "Les Chutes-de-la-Chaudière-Ouest",
	Anjou = "Anjou",
}

export const DevelopmentTypes = Object.values(DevelopmentType)

export const SalesProcessStatus = {
	open: "open",
	accepted_psa: "accepted_psa",
}
/** The Zod schema used to validate a listing */
export const ListingSchema = z.object({
	/// the listing id
	id: z.number(),
	hubspot_record_id: z.string(),
	type: z.nativeEnum(LayerType),
	typeDev: z.string(),
	typeDevArray: z.array(z.nativeEnum(DevelopmentType)),
	constructible: z.nullable(z.number()),
	superficy: z.number(),
	longitude: z.number(),
	latitude: z.number(),
	price: z.nullable(z.number()),
	pricePerSqft: z.nullable(z.number()),
	pricePerConstructibleSqft: z.nullable(z.number()),
	link: z.nullable(z.string()),
	address: z.string(),
	city: z.string(),
	state: z.string(),
	postal_code: z.string(),
	image: z.nullable(z.string()),
	off_market_radius: z.number(),
	sales_process_status: z.nativeEnum(SalesProcessStatus),
	launch_date: z.date().optional(),
	launch_date_ms: z.number().optional(),
	call_for_bids: z.boolean(),
	broker_email: z.string(),
	broker_first_name: z.string(),
	broker_last_name: z.string(),
	broker_phone_number: z.string(),
	broker_picture_url: z.string(),
})

/** A listing is the primary thing our app displays. It represents lots
 * suitable for redevelopment on the market (or likely to be, ex: off_market),
 * either through Landerz brokerage activities or another broker */
export interface Listing extends z.infer<typeof ListingSchema> {}

/** The Zod schema used to validate a parcel (GraphQL version) */
export const ParcelSchema = z.object({
	parcelID: z.string(),
	location: z.object({
		coordinates: z.object({
			longitude: z.string(),
			latitude: z.string(),
		}),
	}),
})

export interface Parcel extends z.infer<typeof ParcelSchema> {}

/** The Zod schema used to validate a prospect (GraphQL version) */
export const ProspectSchema = z.object({
	prospectID: z.string(),
	potential: z.nativeEnum(DevelopmentPotential),
})

export interface Prospect extends z.infer<typeof ProspectSchema> {}

/** The Zod schema used to validate and transform a transaction */
export const TransactionSchema = z
	.object({
		transactionID: z.string(),
		parcels: z.array(
			z
				.object({
					parcelID: z.string().nullish(),
				})
				.nullish()
		),
		deedOfSaleDate: z.string().nullish(),
		seller: z.object({
			name: z.string().nullish(),
			neq: z.string().nullish(),
		}),
		buyer: z.object({
			name: z.string().nullish(),
			neq: z.string().nullish(),
		}),
		location: z.object({
			coordinates: z.object({
				longitude: z.string().nullish(),
				latitude: z.string().nullish(),
			}),
			address: z.string().nullish(),
			municipality: z.string().nullish(),
			borough: z.string().nullish(),
		}),
		superficy: z
			.object({
				squareMeters: z.number().nullish(),
				squareFeet: z.number().nullish(),
			})
			.nullish(),
		price: z.number(),
	})
	.transform(raw => {
		return {
			id: raw.transactionID,
			location: {
				...raw.location,
				parcelNumbers: raw.parcels.map(parcel => parcel?.parcelID),
				area: raw.superficy?.squareFeet,
			},
			deal: {
				price: raw.price,
				registryDate: raw.deedOfSaleDate ? new Date(raw.deedOfSaleDate) : undefined,
				seller: {
					name: raw.seller?.name,
					neq: raw.seller?.neq,
				},
				buyer: {
					name: raw.buyer?.name,
					neq: raw.buyer?.neq,
				},
			},
			registrationNumber: raw.transactionID,
		}
	})

export interface Transaction extends z.infer<typeof TransactionSchema> {}

/** The Zod schema used to validate a municipality */
export const MunicipalitySchema = z.object({
	name: z.string(),
})

export interface Municipality extends z.infer<typeof MunicipalitySchema> {}
