<template>
	<Pin class="pin" :state="state">$</Pin>
</template>

<script setup>
import { computed } from "vue"
import Pin from "@/components/Pin.vue"

const props = defineProps({
	included: {
		type: Boolean,
		default: false,
	},
	excluded: {
		type: Boolean,
		default: false,
	},
})

// prettier-ignore
const state = computed(() =>
	(props.included && 'check') ||
	(props.excluded && 'cross')
)
</script>

<style scoped>
.pin {
	--background-color: #f6925a;
}
</style>
